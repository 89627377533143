import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { xkPanel } from "./models/xkPanel";

// import { Route, Routes } from 'react-router-dom';
import Footer from './layout/Footer';
import Header from './layout/Header';
import HomePage from './layout/HomePage';
import PanelCard from './layout/PanelCard';
import PanelEdit from './layout/PanelEdit';

function App() {

  const pnl = {} as xkPanel;

  return (
    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path='/' element={<HomePage />} />
            <Route path='/PanelCard' element={<PanelCard panel={pnl} />} />
            {/* <Route path='/PanelNewEdit' element={<PanelNewEdit />} /> */}
            <Route path='/panelEdit/:id' element={<PanelEdit />} />
            <Route path='/panelEdit' element={<PanelEdit />} />
          </Route>

        </Routes>
      </BrowserRouter>
      <Footer />
    </div>

  );
}

export default App;
