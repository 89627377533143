import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import agent from '../api/agent'
import { panelListDB } from "../models/panelListDB";
import { rackPanelListDB } from "../models/rackPanelListDB";

// import { CaretIcon, ModalCloseButton } from 'react-responsive-select'; //  Select,

// for default styles...
// import 'react-responsive-select/dist/react-responsive-select.css';

import Select from 'react-select'
import PriceBreakdown from './PriceBreakdown';

// https://codesandbox.io/s/4w6wmo1n1x?file=/src/index.tsx




export default function PanelEdit() {

    const [isShownPanel, setIsShownPanel] = useState(false);
    // const [isShownPanel1, setIsShownPanel1] = useState(false);

    const [isShownSpacer, setIsShownSpacer] = useState(false);
    const [isShownModules, setIsShownModules] = useState(false);
    const [isShownRackMount, setIsShownRackMount] = useState(false);

    const [image, setImage] = useState({ imageURL: agent.PanelsDBList[0].imageURL });  //    "../images/Panel - 3 KU.png" 
    const [selectedPnlID] = useState({ pnlID: agent.PanelsDBList[0].id });
    // var selectedPnlID = 3;

    useEffect(() => {
        if (image) {
            setImage(image);

            // setImage({
            //     imageURL: image,
            // })
        }
    }, [image]);


    // var imageURL = "../images/Panel - 3 KU.png";

    function handleClickPanel() {
        // 👇️ toggle shown state
        setIsShownPanel(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    function handleClickSpacer() {
        // 👇️ toggle shown state
        setIsShownSpacer(current => !current);
    };

    function handleClickModules() {
        // 👇️ toggle shown state
        setIsShownModules(current => !current);
    };

    function handleClickRackMount() {
        // 👇️ toggle shown state
        setIsShownRackMount(current => !current);
    };

    function handleRackInputChange(event: any) {
        // console.log(image.imageURL);
        console.log('onchange call rack change');
        console.log(event);
    }

    function handlePanelInputChange(event: any) {

        console.log('onchange call panel change');
        console.log(event);

        // change panel image when selected value gets changed
        setImage({
            imageURL: event.imageURL
        });

        // console.log('selectedPnlID.selectedPnlID');
        // console.log(image.imageURL);
        // console.log(selectedPnlID.pnlID);

        selectedPnlID.pnlID = event.id;
        image.imageURL = event.imageURL;
        console.log('=============');
        console.log(image.imageURL);
        console.log(selectedPnlID.pnlID);
    }

    function sizeUpClicked() {

        console.log('size up clicked');
        console.log(selectedPnlID.pnlID);
        if (agent.PanelsDBList.some(x => x.id === selectedPnlID.pnlID + 1)) {
            selectedPnlID.pnlID = selectedPnlID.pnlID + 1;

            console.log('size up clicked---1');
            console.log(selectedPnlID);

            image.imageURL = agent.PanelsDBList.find(x => x.id === selectedPnlID.pnlID)?.imageURL!;

            setImage({
                imageURL: image.imageURL
            });
        }
    }

    function sizeDownClicked() {
        console.log('size down clicked');
        console.log(selectedPnlID.pnlID);
        if (agent.PanelsDBList.some(x => x.id === selectedPnlID.pnlID - 1)) {
            selectedPnlID.pnlID = selectedPnlID.pnlID - 1;

            console.log('size down clicked---1');
            console.log(selectedPnlID);

            image.imageURL = agent.PanelsDBList.find(x => x.id === selectedPnlID.pnlID)?.imageURL!;

            setImage({
                imageURL: image.imageURL
            });
        }
    }

    const { id } = useParams()

    var panel: any;
    if (id === undefined) {
        // create new Panel
        // console.log('id undefined R1')
        panel = agent.addPanel();
    }
    else {
        // console.log('getPanel R1')
        panel = agent.getPanel(parseInt(id!));
    }

    return (
        <>
            <div className="main-containter">
                <h2>{panel?.name}</h2>
                <div className="button-naming-container">
                    <a href="/" style={{ paddingRight: '5px' }} ><div className="button">Rename Project</div></a>
                    <a href="/" style={{ paddingRight: '5px' }}><div className="button">Save Project</div></a>
                    <a href="/" style={{ paddingRight: '5px' }}><div className="button">Undo</div></a>
                    <a href="/" style={{ paddingRight: '5px' }}><div className="button">Redo</div></a>
                    {/* <Button component={Link} to={`/panelEdit/${panel.id}`} size="small">Rename Project</Button>
            <Button component={Link} to={`/panelEdit/${panel.id}`} size="small">Save Project</Button>
            <Button component={Link} to={`/panelEdit/${panel.id}`} size="small">Undo</Button>
            <Button component={Link} to={`/panelEdit/${panel.id}`} size="small">Redo</Button> */}
                </div>
            </div>

            <div className="main-containter" style={{ minHeight: '70vh' }} >

                <div className="col-md-3 col-sm-4 editor-container">

                    <div className="editor-options-container clearfix" id="PanelRackOptions">
                        <div className="editor-options-title-container col-xs-9">
                            <p><strong>Panel and Rack Options</strong></p>
                        </div>

                        <div className="editor-options-arrow-container col-xs-3">
                            <div id="myBtn1">
                                <img onClick={handleClickPanel} src="../images/Graphic_dropdown arrow up.png" alt="more info arrow" />
                            </div>
                        </div>

                        {isShownPanel && (
                            <>
                                {/* < id="more1"> */}
                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Panel - 28 KU.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-5">
                                            <p id="title"><strong>Panel</strong></p>
                                            <p id="description">Custom Length</p>
                                            <p id="description">Base housing unit</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            {/* <div className="dropdown-box"> */}
                                            <div className="dropdown-box">
                                                {/* 28 KU ▼</div> */}
                                                <Select<panelListDB>

                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderColor: state.isFocused ? 'grey' : 'red',
                                                            lineHeight: '12px',
                                                            fontSize: '9px',
                                                            color: '#515151',
                                                            backgroundColor: '#F1F0F0',
                                                            width: '100%',
                                                            height: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            border: 'thin solid #C2C1C1',
                                                            whiteSpace: 'nowrap'
                                                        }),
                                                    }}
                                                    // className="dropown-box"
                                                    // classNamePrefix="dropown-box"
                                                    defaultValue={agent.PanelsDBList[0]}
                                                    getOptionLabel={(pnl: panelListDB) => pnl.name}
                                                    getOptionValue={(pnl: panelListDB) => pnl.id.toString()}
                                                    options={agent.PanelsDBList}
                                                    value={agent.PanelsDBList.find(x => x.id === selectedPnlID.pnlID)}
                                                    // isClearable={true}
                                                    // backspaceRemovesValue={true}
                                                    onChange={handlePanelInputChange}

                                                />
                                            </div>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Rack - 5 RU.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-5">
                                            <p id="title"><strong>Rack</strong></p>
                                            <p id="description">Custom Height</p>
                                            <p id="description">Rack Mount Attachment</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="dropdown-box">
                                                {/* 5 RU ▼ */}
                                                <Select<rackPanelListDB>
                                                     styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderColor: state.isFocused ? 'grey' : 'red',
                                                            lineHeight: '12px',
                                                            fontSize: '9px',
                                                            color: '#515151',
                                                            backgroundColor: '#F1F0F0',
                                                            width: '100%',
                                                            height: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            border: 'thin solid #C2C1C1',
                                                            whiteSpace: 'nowrap'
                                                        }),
                                                    }}
                                                    // value={this.state.selectedAnimal} 
                                                    getOptionLabel={(rackPanel: rackPanelListDB) => rackPanel.name}
                                                    getOptionValue={(rackPanel: rackPanelListDB) => rackPanel.id.toString()}
                                                    options={agent.rackPanelDBList}
                                                    defaultValue={agent.rackPanelDBList[0]}
                                                    // isClearable={true}
                                                    // backspaceRemovesValue={true}
                                                    onChange={handleRackInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button">
                                                <img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>




                    <div className="editor-options-container clearfix">
                        <div className="editor-options-title-container col-xs-9">
                            <p><strong>Spacers</strong></p>
                        </div>
                        <div className="editor-options-arrow-container col-xs-3">
                            <div id="myBtn2">
                                <img onClick={handleClickSpacer} src="../images/Graphic_dropdown arrow up.png" alt="more info arrow" />
                            </div>
                        </div>
                        {isShownSpacer && (
                            <>
                                {/*   <span id="more2"> */}
                                {agent.spacersDBList.map(name => (
                                    <div className="editor-options-dropdown-container col-xs-12 clearfix"  key={name.id.toString()}>
                                        <div className="editor-options-dropdown-item" >
                                            <div className="editor-options-dropdown-image col-xs-3">
                                                <img className="editor-options-image" src={name.imageURL} alt="" />
                                            </div>
                                            <div className="editor-options-dropdown-info col-xs-7">
                                                <p id="title"><strong>{name.name}</strong></p>
                                                <p id="description">Width: {name.sizing}</p>
                                                <p id="description">{name.description}</p>
                                            </div>
                                            <div className="editor-options-dropdown-button col-xs-2">
                                                <div className="button editor-options-button">
                                                    <img src="../images/graphic_Plus.png" alt="add" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Spacer - half KU.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>Small Spacer</strong></p>
                                            <p id="description">Width: 1/2 KU</p>
                                            <p id="description">Metal spacer to go between modules</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button">
                                                <img src="../images/graphic_Plus.png" alt="add" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Spacer - 1 KU.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>Large Spacer</strong></p>
                                            <p id="description">Width: 1 KU</p>
                                            <p id="description">Metal spacer to go between modules</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button">
                                                <img src="../images/graphic_Plus.png" alt="add" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        )}
                    </div>

                    <div className="editor-options-container clearfix">
                        <div className="editor-options-title-container col-xs-9">
                            <p><strong>Modules</strong></p>
                        </div>
                        <div className="editor-options-arrow-container col-xs-3">
                            <div onClick={handleClickModules} id="myBtn3"><img src="../images/Graphic_dropdown arrow up.png" alt="more info arrow" /></div>
                        </div>
                        {isShownModules && (
                            <>
                                {/* <span id="more3"> */}
                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK- 12.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-12</strong></p>
                                            <p id="description">Width: 2 KU</p>
                                            <p id="description">12 programmable keys</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-18.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-18</strong></p>
                                            <p id="description">Width: 3 KU</p>
                                            <p id="description">18 programmable keys</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-12 Tbar.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-14 T-bar</strong></p>
                                            <p id="description">Width: 3 KU</p>
                                            <p id="description">14 programmable keys with a T-bar</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-24.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-24</strong></p>
                                            <p id="description">Width: 4 KU</p>
                                            <p id="description">24 programmable keys</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK 12 Jog & Shuttle.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-12 Jog & Shuttle</strong></p>
                                            <p id="description">Width: 4 KU</p>
                                            <p id="description">12 programmable keys with a Jog & Shuttle</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-12 Joystick.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-12 Joystick</strong></p>
                                            <p id="description">Width: 4 KU</p>
                                            <p id="description">12 programmable keys with a Joystick</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-12 Trackball.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-12 Trackball</strong></p>
                                            <p id="description">Width: 4 KU</p>
                                            <p id="description">12 programmable keys with a Trackball</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-4 Faders.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-4 Fader</strong></p>
                                            <p id="description">Width: 4 KU</p>
                                            <p id="description">4 programmable keys with 4 Faders</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - QWERTY.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-84 QWERTY</strong></p>
                                            <p id="description">Width: 16 KU</p>
                                            <p id="description">Reprogrammable QWERTY keyboard</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Module - XK-96.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XK-96</strong></p>
                                            <p id="description">Width: 16 KU</p>
                                            <p id="description">96 programmable keys</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>


                            </>
                        )}
                    </div>




                    <div className="editor-options-container clearfix">
                        <div className="editor-options-title-container col-xs-9">
                            <p><strong>Rack Mounts</strong></p>
                        </div>
                        <div className="editor-options-arrow-container col-xs-3">
                            <div id="myBtn4">
                                <img onClick={handleClickRackMount} src="../images/Graphic_dropdown arrow up.png" alt="more info arrow" /></div>
                        </div>

                        {isShownRackMount && (
                            <>
                                {/* <span id="more4"> */}
                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Rack Mount - Module Holder.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>Module Holder</strong></p>
                                            <p id="description">Height: 3 RU</p>
                                            <p id="description">Add modules to your rack</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button"><img src="../images/graphic_Plus.png" alt="add" /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="editor-options-dropdown-container col-xs-12 clearfix">
                                    <div className="editor-options-dropdown-item">
                                        <div className="editor-options-dropdown-image col-xs-3">
                                            <img className="editor-options-image" src="../images/Rack Mount - XKE-40.png" alt="" />
                                        </div>
                                        <div className="editor-options-dropdown-info col-xs-7">
                                            <p id="title"><strong>XKE-40</strong></p>
                                            <p id="description">Height: 1 RU</p>
                                            <p id="description">40 programmable keys</p>
                                        </div>
                                        <div className="editor-options-dropdown-button col-xs-2">
                                            <div className="button editor-options-button">
                                                <img src="../images/graphic_Plus.png" alt="add" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>

                </div>

                <div className="col-md-6 col-sm-4 editor-container">
                    {/* <a href="/"> */}
                    <div className="button main-content-button-symbol" style={{ float: 'left', margin: '5px' }} >
                        <img className="main-content-button-symbol-image" src="../images/graphic_Zoom In.png" alt="Zoom In" />
                    </div>
                    {/* </a> */}
                    {/* <a href="/"> */}
                    <div className="button main-content-button-symbol" style={{ float: 'left', margin: '5px' }} >
                        <img className="main-content-button-symbol-image" src="../images/graphic_Zoom Out.png" alt="Zoom Out" />
                    </div>
                    {/* </a> */}
                    <div >
                        <button className="button" style={{ width: '100px', margin: '1px', textAlign: 'left' }} onClick={sizeUpClicked}>
                            Size Up
                        </button>
                    </div>

                    <div>
                        <button className="button" style={{ width: '100px', margin: '1px', textAlign: 'left' }} onClick={sizeDownClicked}>
                            Size Down
                        </button>
                    </div>


                    <div className="editor-image-container">
                        <img className="main-media-image" src={image.imageURL} alt="background project" />
                        <div className="editor-image-buttons">
                            {/* <div className="button">Size Up </div>
                            <div className="button">Size Down</div> */}

                            {/* <div >
                                <button className="button" style={{ width: '100px', margin: '1px', textAlign: 'left' }} onClick={sizeUpClicked}>Size Up</button>
                            </div>

                            <div>
                                <button className="button" style={{ width: '100px', margin: '1px', textAlign: 'left' }} onClick={sizeDownClicked}>Size Down</button>
                            </div> */}
                        </div>
                    </div>

                </div>

                <PriceBreakdown />
                {/* <div className="col-md-3 col-sm-4 editor-container editor-container-price">
                    <div className="editor-price-container">
                        <div className="editor-price-title">
                            <p><strong>Price Breakdown</strong></p>
                        </div>

                        <div className="editor-price-breakdown">

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Panel - 12 KU</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Module - XK-12 Jog & Shuttle</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Panel - 12 KU</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Panel - 12 KU</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                        </div>

                        <div className="editor-price-total">
                            <p><strong>Total: $454.85</strong></p>
                        </div>

                        <div className="editor-price-buttons">
                            <div className="button editor-price-button-symbol"><img src="../images/graphic_Share-Export.png" alt="export" /></div>
                            <div className="button editor-price-button-text">ORDER DEVICE</div>
                            <div className="button editor-price-button-symbol"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                        </div>

                    </div>
                </div> */}

            </div>

        </>
    )

}

// function Box() {
//     return (
//         <div>
//             <h2>Box</h2>
//         </div>
//     );
// }