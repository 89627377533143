// import '../App.css';

import '../css/style.css';

export default function Header() {
  return (
      <div className="header">
        <nav className="top-bar-nav">
          <div className="header-nav-home"><h1>X-Keys Creator</h1></div>
          <div className="header-nav-button"><a href="/">Login</a></div>
          <div className="header-nav-button"><a href="/">My Devices</a></div>
        </nav>
      </div>
  )
}