// import { xkPanel } from "../models/xkPanel";

import { modulesListDB } from "../models/modulesListDB";
import { panelListDB } from "../models/panelListDB";
import { rackMountDBList } from "../models/rackMountDBList";
import { rackPanelListDB } from "../models/rackPanelListDB";
import { spacersListDB } from "../models/spacersListDB";

const Xk_Panels = [{ id: 1, description: "X-keys Panel 1 desc", name: "X-keys Panel 1", lastEdited: "9/2/2022", pictureUrl: '../images/XK-24 background.png' },
{ id: 2, description: "desc2", name: "X-keys Panel 2", lastEdited: "9/2/2022", pictureUrl: '../images/XK-60 background.png' },
{ id: 3, description: "desc3", name: "X-keys Panel 3", lastEdited: "9/2/2022", pictureUrl: '../images/XK-24 background.png' },
{ id: 4, description: "desc 4", name: "X-keys Panel 4", lastEdited: "9/2/2022", pictureUrl: '../images/XK-80 background.png' },
{ id: 5, description: "desc 5", name: "X-keys Panel 5", lastEdited: "9/2/2022", pictureUrl: '../images/XK-80 background.png' }
];

const rackPanelDBList: rackPanelListDB[] = [{ id: 1, name: "1 RU", imageURL: "../images/Rack - 1 RU.png", description: "Rack Mount Attachment", price: 100 },
{ id: 2, name: "2 KU", imageURL: "../images/Rack - 2 RU.png", description: "Rack Mount Attachment", price: 100 },
{ id: 3, name: "3 KU", imageURL: "../images/Rack - 3 RU.png", description: "Rack Mount Attachment", price: 100 },
{ id: 4, name: "4 KU", imageURL: "../images/Rack - 4 RU.png", description: "Rack Mount Attachment", price: 100 },
{ id: 5, name: "5 KU", imageURL: "../images/Rack - 5 RU.png", description: "Rack Mount Attachment", price: 100 }
];

const spacersDBList: spacersListDB[] = [
    { id: 1, name: "Small Spacer", sizing: "1/2 KU", imageURL: "../images/Spacer - half KU.png", description: "Metal spacer to go between modules", price: 100 },
    { id: 2, name: "Large Spacer", sizing: "1 KU", imageURL: "../images/Spacer - 1 KU.png", description: "Metal spacer to go between modules", price: 100 }
];

const modulesDBList: modulesListDB[] = [{ id: 1, name: "XK-12", imageURL: "../images/Rack - 1 RU.png", description: "", price: 100 },
{ id: 2, name: "2 KU", imageURL: "../images/Rack - 2 RU.png", description: "", price: 100 }];

const rackMountListDB: rackMountDBList[] = [
    {
        id: 1, name: "XKE-40 Rack Mount", sizing: "1 RU", imageURL: "../images/Rack Mount - Module Holder.png",
        description: "40 Programmable Keys", price: 100
    },
    {
        id: 2, name: "Module Holder Rack Mount", sizing: "3 RU", imageURL: "../images/Rack Mount - XKE-40.png",
        description: "Add Modules on your Rack", price: 100
    }];

const PanelsDBList: panelListDB[] = [{ id: 3, name: "3 KU", imageURL: "../images/Panel - 3 KU.png", description: "Base housing for Modules", price: 100 },
{ id: 4, name: "4 KU", imageURL: "../images/Panel - 4 KU.png", description: "Base housing for Modules", price: 100 },
{ id: 5, name: "5 KU", imageURL: "../images/Panel - 5 KU.png", description: "Base housing for Modules", price: 100 },
{ id: 6, name: "6 KU", imageURL: "../images/Panel - 6 KU.png", description: "", price: 100 },
{ id: 7, name: "7 KU", imageURL: "../images/Panel - 7 KU.png", description: "", price: 100 },
{ id: 8, name: "8 KU", imageURL: "../images/Panel - 8 KU.png", description: "", price: 100 },
{ id: 9, name: "9 KU", imageURL: "../images/Panel - 9 KU.png", description: "", price: 100 },
{ id: 10, name: "10 KU", imageURL: "../images/Panel - 10 KU.png", description: "", price: 100 },
{ id: 11, name: "11 KU", imageURL: "../images/Panel - 11 KU.png", description: "", price: 100 },
{ id: 12, name: "12 KU", imageURL: "../images/Panel - 12 KU.png", description: "", price: 100 },
{ id: 13, name: "13 KU", imageURL: "../images/Panel - 13 KU.png", description: "", price: 100 },
{ id: 14, name: "14 KU", imageURL: "../images/Panel - 14 KU.png", description: "", price: 100 },
{ id: 15, name: "15 KU", imageURL: "../images/Panel - 15 KU.png", description: "", price: 100 },
{ id: 16, name: "16 KU", imageURL: "../images/Panel - 16 KU.png", description: "", price: 100 },
{ id: 17, name: "17 KU", imageURL: "../images/Panel - 17 KU.png", description: "", price: 100 },
{ id: 18, name: "18 KU", imageURL: "../images/Panel - 18 KU.png", description: "", price: 100 },
{ id: 19, name: "19 KU", imageURL: "../images/Panel - 19 KU.png", description: "", price: 100 },
{ id: 20, name: "20 KU", imageURL: "../images/Panel - 20 KU.png", description: "", price: 100 },
{ id: 21, name: "21 KU", imageURL: "../images/Panel - 21 KU.png", description: "", price: 100 },
{ id: 22, name: "22 KU", imageURL: "../images/Panel - 22 KU.png", description: "", price: 100 },
{ id: 23, name: "23 KU", imageURL: "../images/Panel - 23 KU.png", description: "", price: 100 },
{ id: 24, name: "24 KU", imageURL: "../images/Panel - 24 KU.png", description: "", price: 100 },
{ id: 25, name: "25 KU", imageURL: "../images/Panel - 25 KU.png", description: "", price: 100 },
{ id: 26, name: "26 KU", imageURL: "../images/Panel - 26 KU.png", description: "", price: 100 },
{ id: 27, name: "27 KU", imageURL: "../images/Panel - 27 KU.png", description: "", price: 100 },
{ id: 28, name: "28 KU", imageURL: "../images/Panel - 28 KU.png", description: "", price: 100 }
];


const agent = {
    Xk_Panels,
    PanelsDBList,
    getPanel,
    addPanel,
    rackPanelDBList,
    spacersDBList,
    modulesDBList,
    rackMountListDB
    // Catalog,
    // TestErrors,
    // Basket,
    // Account,
    // Orders,
    // Payments 
    // //Admin

}

function getPanel(id: number) {
    return Xk_Panels.find(x => x.id === id);
}

function addPanel() {

    // this.max = this.people.reduce((a, b) => Math.max(a, b));
    var getNewID = Xk_Panels.length + 1;

    // console.log(getNewID);
    const now = new Date();

    Xk_Panels.push({ id: getNewID, description: "New Pnl", name: "X-keys Panel " + getNewID, lastEdited: now.toLocaleDateString(), pictureUrl: '../images/XK-80 background.png' });

    return getPanel(getNewID);
}

//  const Catalog = {
//     list: () => requests.get('products'),
//     details: (id: number) => requests.get(`products/${id}`) //-,
// }

export default agent;