
import '../css/style.css';

export default function Footer()
{
return(
<div className="footer">
</div>

)}

// import '../App.css';

// export default function Footer()
// {
// return(
// <div className="Footer">
       
//         <div>
//           <h4>PI Engineering</h4>
          
//         </div>
//       </div>

// )}