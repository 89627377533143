import { Link } from "react-router-dom";
import { xkPanel } from "../models/xkPanel";
import PanelCard from "./PanelCard";

interface Props {
    panels: xkPanel[];
}



function PanelList({ panels }: Props) {

    return (
        <>
            {panels.map(panel =>
            (
                <PanelCard key={panel.id} panel={panel} />
            ))}

            <div className="main-item-container col-lg-3 col-md-4 col-sm-6">
                <div className="main-item">
                    {/* <a href="/"> */}
                    <Link to={`/panelEdit/`}>
                        <div className="main-item-new-project">
                            <img src="../images/add_symbol.png" alt="Plus Sign" />
                        </div>
                    </Link>
                    {/* </a> */}
                </div>
            </div>
            {/* <Button variant="contained" onClick={navigateToPanelView} >Add New Panel</Button> */}
            {/* <Button variant="contained" component={Link} to={`/panelEdit/`} size="small">Add New Panel</Button> */}
        </>
    )
}

export default PanelList;