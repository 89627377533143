export default function PriceBreakdown(){

    return(
        <>
          <div className="col-md-3 col-sm-4 editor-container editor-container-price">
                    <div className="editor-price-container">
                        <div className="editor-price-title">
                            <p><strong>Price Breakdown</strong></p>
                        </div>

                        <div className="editor-price-breakdown">

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Panel - 12 KU</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Module - XK-12 Jog & Shuttle</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Panel - 12 KU</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                            <div className="editor-price-breakdown-item">
                                <div className="editor-price-breakdown-item-name col-sm-8">Panel - 12 KU</div>
                                <div className="editor-price-breakdown-item-button-container col-sm-1">
                                    <div className="button editor-price-breakdown-item-button"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                                </div>
                                <div className="editor-price-breakdown-item-price col-sm-3">$ 50.00</div>
                            </div>

                        </div>

                        <div className="editor-price-total">
                            <p><strong>Total: $454.85</strong></p>
                        </div>

                        <div className="editor-price-buttons">
                            <div className="button editor-price-button-symbol"><img src="../images/graphic_Share-Export.png" alt="export" /></div>
                            <div className="button editor-price-button-text">ORDER DEVICE</div>
                            <div className="button editor-price-button-symbol"><img src="../images/graphic_Delete.png" alt="Delete" /></div>
                        </div>

                    </div>
                </div>
        </>
    )
}