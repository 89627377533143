import agent from "../api/agent";
import PanelList from "./PanelList";
// import '../css/style.css';

function HomePage() {

    return (
        <>
            <div className="main-containter">
                <h2>PI Rack and Panel Builder</h2>
                <p>Build your own X-keys creation using our modular based system. Piece your kit together and then submit your final design to our team over at X-keys. Reach out to us with any questions and we can help guide you through the process and offer advice to help you create a unique machine that fits your exact needs.</p>
            </div>

            {/* <div className="main-containter" style="min-height: 70vh;">	 */}
            <div className="main-containter" style={{minHeight: '70vh'}} >	
                <PanelList panels={agent.Xk_Panels} key='1' />
            </div>
        </>
    )
}

export default HomePage;