import { xkPanel } from "../models/xkPanel";
import { Link } from 'react-router-dom';

interface Props {
    panel: xkPanel;
}


export default function PanelCard({ panel }: Props) {

    return (
        <>
            <div className="main-item-container col-lg-3 col-md-4 col-sm-6">
                <div className="main-item">
                    <div className="main-content">
                        <p className="main-content-header">{panel.name}</p>
                        <p className="main-content-desc">Last Edited: {panel.lastEdited}</p>
                        <div className="main-content-button-edit-container">
                            {/* <a href="">
                                <div className="button main-content-button-edit">
                                    EDIT
                                </div>
                            </a> */}
                            <Link to={`/panelEdit/${panel.id}`}>
                                <button className="button main-content-button-edit">EDIT</button>
                            </Link>
                            {/* <Link to="/posts">
                               <button>Posts</button>
                             </Link> */}

                            {/* <div className="button main-content-button-edit">
                                <Button component={Link} to={`/panelEdit/${panel.id}`} >Edit</Button>
                            </div> */}
                        </div>
                        <div className="main-content-button-symbol-container">

                            <a href="/"><div className="button main-content-button-symbol">
                                <img className="main-content-button-symbol-image" src="../images/graphic_Delete.png" alt="Delete" />
                            </div>
                            </a>
                            <a href="/"><div className="button main-content-button-symbol">
                                <img className="main-content-button-symbol-image" src="../images/graphic_Copy.png" alt="Duplicate" /></div></a>
                            <a href="/"><div className="button main-content-button-symbol">
                                <img className="main-content-button-symbol-image" src="../images/graphic_Share-Export.png" alt="Share/Export" />
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="main-media">
                        <img className="main-media-image" src="../images/graphic__12KU_example.png" width="2907" height="2001" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}